<script setup lang="ts">
import Button from '~~/components/design-system/button.vue';
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';
import GenericError from '~/components/user-interface/generic-error.vue';
import { captcha } from 'assets/data/config';

const formLoading: globalThis.Ref<boolean> = ref(false);
const { $authService } = useServices();
const { $t } = useNuxtApp();
const auth = useAuthStore();
const site = useSiteStore();
const colorMode = useColorMode();

const form = ref();
const countryCode: string = site.region;
const errorCode: globalThis.Ref<string> = ref('');

const model = ref({
  password: '',
  password_confirmation: '',
});

const navigateToSuccess = () => {
  // navigate to reset success
  auth.toggleAccountModal(false);
  site.activateModal('passwordResetStepSuccess');
};

// Submit new password
const confirmPasswordChange = () => {
  errorCode.value = null;
  const { password_confirmation, ...otherProps } = model.value;

  const data = { confirmPassword: password_confirmation, ...otherProps };

  formLoading.value = true;

  const accountId: string = auth.user_id;

  $authService
    .passwordResetLoggedIn({
      accountId,
      countryCode,
      ...data,
    })
    .then((data) => {
      if (data?.isSuccessful) {
        // redirect to otp success
        navigateToSuccess();
      } else {
        errorCode.value = data?.error?.code ?? 'general';
      }
    })
    .finally(() => {
      formLoading.value = false;
    });
};
</script>

<template>
  <div
    class="sm:mt-4 mx-auto sm:max-w-[428px] p-4 pt-3 bg-layer-1 sm:rounded-lg flex flex-col justify-center gap-2"
  >
    <span class="font-bold text-base pb-3">
      {{ $t('update-password') }}
    </span>

    <span class="text-sm">{{ $t('enter-new-password') }}</span>

    <div class="flex flex-col gap-2">
      <Vueform
        ref="form"
        :float-placeholders="true"
        :endpoint="false"
        class="flex flex-col gap-2"
        :model-value="model"
        sync
      >
        <template #empty>
          <VueformPasswordElement
            :key="'password'"
            :name="'password'"
            :rules="[
              'required',
              'confirmed',
              'regex:/^(?!.*\\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*\\W)(?:.{8,20})$/',
            ]"
            :messages="{ regex: $t('formErrors.password') }"
            :display-feedback="true"
            :debounce="500"
          />
          <VueformPasswordElement
            :key="'password_conf'"
            :name="'password_confirmation'"
            labelValue="confirm-password"
            placeholderValue="placeholder.confirm-password"
            :display-feedback="false"
            rules="required"
          />

          <div
            v-if="$enabled('website.enablerecaptcha')"
            class="mt-6 flex-center"
          >
            <CaptchaElement
              provider="recaptcha2"
              name="pw-reset-captcha"
              :default="captcha[site.getRegionCode]"
              :is-required="true"
              should-verify
              :options="{
                theme: colorMode.preference === 'dark' ? 'dark' : 'light',
                sitekey: captcha[site.getRegionCode],
              }"
            />
          </div>
        </template>
      </Vueform>

      <!-- Passwords must match -->
      <div v-if="form?.validated && !form?.invalid">
        <div class="flex">
          <CheckCircleIcon
            class="dark:text-primary-blue-500 text-primary-blue-400 icon-success--border w-4"
          />
          <span class="text-xs font-light text-base-priority">
            {{ $t('Passwords-must-match-and-be-valid') }}
          </span>
        </div>
      </div>

      <!-- Error messaging -->
      <div v-if="errorCode" class="flex justify-center">
        <GenericError class="max-w-72 mx-auto" state="danger">
          {{ $t(`jpc-update-password-error-` + errorCode) }}
        </GenericError>
      </div>
    </div>

    <!-- Buttons -->
    <div class="text-sm flex flex-row justify-center">
      <Button
        class="uppercase w-full justify-center text-sm"
        :disabled="!(form?.validated && !form?.invalid)"
        @click="confirmPasswordChange()"
      >
        {{ $t('update-password') }}
      </Button>
    </div>
    <LazyUserInterfaceGenericLoader v-if="formLoading" container />
  </div>
</template>
